<template>
    <v-main>
        <div class="main-content">
        <router-view>
            
        </router-view>
    </div>
    </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: 'Segments',
        beforeRouteEnter (to, from, next) {
            next(vm =>
            {
                vm.$store.commit('setPageName', 'segments');
            }
            )
        },
        computed: {
            ...mapGetters({
                conditionFields: 'segments/getConditionsFields',
                traitsToExport: 'segments/getTraitsToExport',
            })
        },
        async mounted(){
            if (!this.conditionFields) {
                await this.$store.dispatch('segments/awaitGetConditionFields');
            }
            if (!this.traitsToExport.length) {
                await this.$store.dispatch('segments/awaitGetTraitsToExport');
            }
        },
        }
</script>

<style lang="scss" scoped>

</style>